import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { Slide } from './Slide';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { clsx } from 'clsx';
import { MediaRecognition } from '@/components/MediaRecognition/MediaRecognition';
import { PATH_ARTICLES, PATH_INTEGRATION } from '@/constants/spa-routes';
import Link from 'next/link';
import { YouTubeVideoPopUp } from '@/components/YouTubeVideoPopUp/YouTubeVideoPopUp';
import { COSSUPPURT_AI_VIDEO_IFRAME } from '@/constants/links';

type ContentProps = {
  className?: string;
};

const SlidesData = [
  {
    id: 1,
    title: (
      <Typography
        $breakpoint="1024"
        as="h2"
        $type="Heading2"
        $color={COLORS.WHITE}
        className="text-start font-formula"
      >
        Solve Tickets Within Seconds with AI Copilot for Zendesk. Our AI is
        Deeply Custom and Expertly Crafted
      </Typography>
    ),
    subtitle: (
      <Typography $type="Heading4" $color={COLORS.WHITE} className="opacity-60">
        Benefit from 80% faster ticket processing time, 68% increase for
        resolved Zendesk tickets, and 80% boosted team efficiency time!
      </Typography>
    ),
    bottomContent: () => {
      return (
        <div className="md:mt-2-vw mt-4">
          <Link
            href={PATH_INTEGRATION.INTEGRATION_ZENDESK}
            className="underline-offset-2"
          >
            <Typography
              as="span"
              $type="Heading4"
              $weight="600"
              $color={COLORS.GREEN}
              className="underline underline-offset-2 hover:opacity-70 transition"
            >
              Explore more →
            </Typography>
          </Link>
        </div>
      );
    },
    image: '/img/main-page-slider-img1.png',
    imageMobile: '/img/main-page-slider-img1-mobile.png',
    imageClassName: '',
    imageContainerClassName: '',
  },
  {
    id: 2,
    title: (
      <Typography
        $breakpoint="1024"
        as="h2"
        $type="Heading2"
        $color={COLORS.WHITE}
        className="text-start font-formula"
      >
        Enjoy CoSupport AI video presentation. We’ve made with care!
      </Typography>
    ),
    subtitle: (
      <Typography
        $type="Heading4"
        $color={COLORS.WHITE}
        className=" opacity-60"
      >
        Our first video will tell you about CoSupport AI solutions in an
        easy-to-percieve visual format. Stay informed and make fun!
      </Typography>
    ),
    bottomContent: () => {
      return (
        <div className="flex items-center md:mt-2-vw mt-4">
          <YouTubeVideoPopUp
            title="Meet CoSupport AI: Your Generative AI Copilot for Customer Support"
            iframeSrc={COSSUPPURT_AI_VIDEO_IFRAME}
            triggerElement={
              <div className="flex items-center gap-3.5 hover:opacity-70 transition cursor-pointer">
                <svg
                  className="md:h-9-vw md:w-9-vw h-7 w-7"
                  width="35"
                  height="36"
                  viewBox="0 0 35 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="17.5" cy="18" r="17.5" fill="#33FF71" />
                  <path
                    d="M22.4648 17.4857C23.1315 17.8706 23.1315 18.8329 22.4648 19.2178L15.1931 23.4161C14.5264 23.801 13.6931 23.3199 13.6931 22.5501L13.6931 14.1534C13.6931 13.3836 14.5264 12.9025 15.1931 13.2874L22.4648 17.4857Z"
                    fill="#05050D"
                  />
                </svg>
                <Typography
                  $type="Heading4"
                  $weight="600"
                  $color={COLORS.GREEN}
                  className="underline underline-offset-2"
                >
                  Watch Now
                </Typography>
              </div>
            }
          />
        </div>
      );
    },
    image: '/img/main-page-slider-img2.png',
    imageMobile: '/img/main-page-slider-img2-mobile.png',
    imageClassName: '',
    imageContainerClassName: '',
  },
  {
    id: 3,
    title: (
      <Typography
        $breakpoint="1024"
        as="h2"
        $type="Heading2"
        $color={COLORS.WHITE}
        className="text-start font-formula"
      >
        An Award-Winning Company for Your Business to Win
      </Typography>
    ),
    subtitle: (
      <Typography $type="Heading4" $color={COLORS.WHITE} className="opacity-60">
        In May 2024, CoSupport AI was recognized as a leading innovator in
        AI-powered text generation, receiving a prestigious AI World Series
        Award.
      </Typography>
    ),
    bottomContent: () => {
      return (
        <div className="md:mt-2-vw mt-4">
          <Link
            href={PATH_ARTICLES.WORLD_AWARDS}
            className="underline-offset-2"
          >
            <Typography
              as="span"
              $type="Heading4"
              $weight="600"
              $color={COLORS.GREEN}
              className="underline underline-offset-2 hover:opacity-70 transition"
            >
              Explore more →
            </Typography>
          </Link>
        </div>
      );
    },
    image: '/img/main-page-slider-img3.png',
    imageMobile: '/img/main-page-slider-img3-mobile.png',
    imageClassName: 'relative md:right-10-vw max-md:top-10',
    imageContainerClassName: 'max-md:max-w-full',
  },
];

export const Slider = ({ className }: ContentProps) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  const plugin = useRef(Autoplay({ delay: 6000 }));

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div
      className={clsx(
        'max-md:mb-20 h-full w-full min-h-full flex flex-col items-center transition duration-500 justify-between backdrop-blur-[22px] md:rounded-t-3xl md:rounded-b-none rounded-xl md:m-0 m-4',
        current === 1 && 'bg-[#00262BCC]',
        current === 2 && 'bg-[#1A2B3ECC]',
        current === 3 && 'bg-[#1A1E3ACC]',
        className,
      )}
    >
      <Container padding="md:px-12">
        <Container>
          <div className="md:pt-10-vw md:pb-2-vw max-md:py-7">
            <Carousel
              plugins={[plugin.current]}
              setApi={setApi}
              className="w-full"
            >
              <CarouselContent>
                {SlidesData.map((slide, index) => (
                  <CarouselItem key={`${slide.id}-${index}`}>
                    <Slide
                      title={slide.title}
                      subtitle={slide.subtitle}
                      bottomContent={slide.bottomContent()}
                      image={slide.image}
                      imageMobile={slide.imageMobile}
                      imageClassName={slide.imageClassName}
                      imageContainerClassName={slide.imageContainerClassName}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious className="bg-transparent border-[#FFFFFF33] text-white md:!flex hidden" />
              <CarouselNext className="bg-transparent border-[#FFFFFF33] text-white md:!flex hidden" />
            </Carousel>
            <div className="flex gap-4 justify-center py-2.5 text-center text-sm text-muted-foreground max-md:absolute max-md:-bottom-12">
              {SlidesData.map((slide, index) => (
                <div
                  onClick={() => api?.scrollTo(index)}
                  key={`${slide.id}`}
                  className={clsx(
                    'md:h-[0.625vw] md:w-[0.625vw] h-[9px] w-[9px] bg-white rounded-full cursor-pointer',
                    slide.id !== current && 'opacity-40',
                  )}
                />
              ))}
            </div>
          </div>
        </Container>
      </Container>
      <MediaRecognition
        isDark={false}
        className="!bg-transparent lg:block hidden"
      />
    </div>
  );
};
